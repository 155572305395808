.list-group-item.dark {
  background-color: transparent !important;
  color: #8e9091 !important;
}
.list-group-item.dark.active {
  background-color: $primary-color !important;
  color: #fff !important;
}

.dark .table {
  color: inherit !important;
}

.dark .table {
  * > tr:nth-of-type(odd) > * {
    color: inherit !important;
  }
}

.dark .table {
  * > tr:nth-of-type(even) > * {
    color: inherit !important;
  }
}

.dark .table {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.dark .card,
.dark .profile,
.dark .profile .dropdown-item,
.dark .input-group-text,
.dark .form-control,
.dark .modal-content,
.dark .input,
.dark .form-control,
.dark .css-qc6sy-singleValue,
.dark .css-1s2u09g-control,
.dark .css-1pahdxg-control,
.dark .css-26l3qy-menu,
.dark .css-1insrsq-control,
.dark .dwPlXY,
.dark .fyrdjl {
  color: #c1c1c1 !important;
  background-color: #202025 !important;
}
.dark .iSAVrt,
.dark .gKbhqU,
.dark .payable_title {
  color: #c1c1c1 !important;
  background-color: #212529 !important;
}

.dark .filter .active {
  background-color: $primary-color !important;
  color: #fff !important;
}

.dark .modal-header,
.dark .kVrXuC,
.dark .bzRnkJ {
  background-color: inherit !important;
  color: inherit !important;
}

.dark .cursor-cart {
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"%3E%3Cpath fill="white" d="M24 0C10.7 0 0 10.7 0 24s10.7 24 24 24h52.1l60.3 316.5c2.2 11.3 12.1 19.5 23.6 19.5h328c13.3 0 24-10.7 24-24s-10.7-24-24-24H179.9l-9.1-48h317c14.3 0 26.9-9.5 30.8-23.3l54-192C578.3 52.3 563 32 541.8 32H122l-2.4-12.5C117.4 8.2 107.5 0 96 0H24zm152 512c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm336-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM252 160c0-11 9-20 20-20h44V96c0-11 9-20 20-20s20 9 20 20v44h44c11 0 20 9 20 20s-9 20-20 20h-44v44c0 11-9 20-20 20s-20-9-20-20v-44h-44c-11 0-20-9-20-20z"%2F%3E%3C%2Fsvg%3E'),
    auto;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: $primary-color !important;
}