.cursor_pointer{
  cursor: pointer;
}
.request-product {
  margin-bottom: 20px;
  transition: 0.3s;

  &:hover {
    background-color: #feeee757;
    transform: translate(2px, 2px);
  }

  .product-info {
    display: flex;
    // align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;
  }
  .product-info-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
}
.image-div{
  position: relative;
}
.img-count-div{
  background: #8E63FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-weight: 500;
}

.our-request-price {
  width: 315px;
  height: 325px;
  border-radius: 24.5px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 15px 0px #00000026;
}

.accept-request {
  padding-top: 30px;
  padding-bottom: 30px;
}

.payout-list {

  .payout-options {
    display: flex;
    padding-bottom: 2rem;
  }

  .payout-item {
    max-width: 15rem;
    min-width: 10rem;
    cursor: pointer;

    &:first-child {
      margin-right: 2rem;
    }

    &.active {
      box-shadow: 1px 3px 5px 0px #00000024;
      position: relative;

      .checked-icon {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 5px;
      }
    }
  }
}

.confirm-request {
  .request-details {
    border-bottom: 1px solid $color-gray;
    padding: 1rem 0;
  }

  .request-details:last-child {
    border-bottom: 2px solid $color-gray-dark;
  }

  .payment-options {
    display: flex;
    padding-bottom: 2rem;
  }

  .payment-item {
    width: 12rem;
  }

  .request-card {
    min-width: 18rem;
    margin-bottom: 2rem;
    min-height: 330px;
  }
}

.card-item {
  max-width: 15rem;
  min-width: 8rem;
  text-align: center;

  &:first-child {
    margin-right: .5rem;
  }

  &.active {
    box-shadow: 1px 3px 5px 0px #00000024;
    position: relative;

    .checked-icon {
      position: absolute;
      top: 10px;
      right: 15px;
      padding: 5px;
    }
  }
}