/* DivTable.com */

.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  // border-bottom: 0.3px solid rgba(169, 189, 204, 0.418);
  // border-top: 0.3px solid rgba(169, 189, 204, 0.418);
  display: table-cell;
  padding: 8px 5px;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableHead {
  display: table-row-group;
  border: none;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

// Purchase cart table
.purchase_cart {
  table {
    thead tr th {
      background-color: $color-gray;
      font-size: 14px;
    }
  }
}
