.category-card {

    & {
        padding: 0;
        position: relative;
        display: inline-block;
        z-index: 0;

        .form-check-input {
            position: absolute;
            right: 20px;
            z-index: 1;
            top: 10px;
            left: auto;
        }
    }

    .category-card-item {
        padding: 20px 30px;
        border-radius: $border-radius;
        min-width: 170px;
        min-height: 145px;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.46);
        cursor: pointer;
    }

    .category-card-item-content {
       display: flex;
       flex-direction: column;
       align-items: center;

       img {
           width: 50px;
           height: 50px;
           margin-bottom: 10px;
       }

       p {
           margin-bottom: 0;
       }
    }

    @media only screen and (max-width: 385px) {
        .category-card-item {
            padding: 15px 20px;
            min-width: 130px;
            min-height: 110px;
        }
    }
}