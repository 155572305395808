.swal2-container.swal2-center>.swal2-popup {
  border-radius: 50px;
  width: 20em;

  .swal2-title {
    padding: 0.5em 1em 0;
    font-size: 1.275em;
  }
  
  .swal2-icon {
    width: 3em;
    height: 3em;
    margin: 1.5em auto 0.6em;
    line-height: 3em;
  }

  button {
      border-radius: 30px;
  }

  .swal2-html-container {
    margin: 1em 1em 0.3em;
  }
}