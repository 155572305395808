.modal {
    &-header {
        background-color: $color-white-gray;
        --bs-modal-header-padding: .7rem;
        font-family: 'DM Sans',sans-serif;
        font-weight: 500;
        color: #5E5873;
    }

   .modal-header.hide-title {
        background-color: inherit;
        --bs-modal-header-padding: inherit;
        font-family: inherit;
        font-weight: inherit;
        color: inherit;
        border-bottom: 0;
    }
}

.btn-close {
    transition-duration: .3s;
}

.btn-close:hover {
    transform: translateY(3px);
}

