
/********** MEDIA QUERY INDEX *********
1. START MEDIUM DEVICES, TABLETS. SIZE(min-width: 992px) and (max-width: 1200px)
2. START SMALL DEVICES, TABLETS. SIZE(min-width: 768px) and (max-width: 991px)
3. START SMALL DEVICES, PHONES. SIZE(max-width: 767px)
4. START EXTRA SMALL DEVICES, PHONES. SIZE(max-width: 480px)
5. START EXTRA SMALL DEVICES, PHONES. SIZE(max-width: 320px)
*****************************************/


/******** Toogle navbar button for mobile view ******/
.navbar-toggler {
  position: relative;
  padding: 1.1rem;
  border: none;
  cursor: pointer;
  outline: none;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler span {
  position: absolute;
  background-color: #757373;
  height: 2px;
  -moz-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.navbar-toggler span:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #757373;
  -webkit-transition: -webkit-transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.navbar-toggler span:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #757373;
  -webkit-transition: -webkit-transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:before {
  background-color: #757373;
  -moz-transform: translateY(10px) translateX(0) rotate(45deg);
  -o-transform: translateY(10px) translateX(0) rotate(45deg);
  -ms-transform: translateY(10px) translateX(0) rotate(45deg);
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  transform: translateY(10px) translateX(0) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:after {
  background-color: #757373;
  -moz-transform: translateY(-10px) translateX(0) rotate(-45deg);
  -o-transform: translateY(-10px) translateX(0) rotate(-45deg);
  -ms-transform: translateY(-10px) translateX(0) rotate(-45deg);
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  transform: translateY(-10px) translateX(0) rotate(-45deg);
}

.navbar-toggler:not(.collapsed) span {
  background-color: transparent;
  background-image: none;
}


.desktop-view.navbar {
  .navbar-nav .nav-link {
    padding: 15px 10px;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    left: auto;
    right: 0;
    border-radius: 15px;
  }
}

.language-toggle-btn {
  img {
    width: 23px;
  }

  &::after {
    display: none;
  }

  &,
  &:focus,
  &:hover,
  &:visited,
  &:active {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}


/************************************************
1. START MEDIUM DEVICES, TABLETS. SIZE(min-width: 992px) and (max-width: 1200px)
************************************************/
@media (min-width: 992px) and (max-width: 1200px) {

}
/************************************************
End medium Devices, Desktops
************************************************/



/************************************************
2. START SMALL DEVICES, TABLETS. SIZE(min-width: 768px) and (max-width: 991px)
************************************************/
@media (max-width: 991px) {
    .mobile-view.navbar {
      .container,
      .container-fluid {
        padding: 0;
      }

      .toggle-logo-container {
        padding: 10px 15px;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .navbar-brand {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .navbar-collapse.collapse {
        background-color: $color-white;
        box-shadow: 0px 9px 18px 0px #0000002e;

        .nav-link {
          transition: none;
          border-bottom: 1px solid $color-gray-light;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .nav-round-btn {
        margin: 10px 15px !important;
      }

      .language-toggle-btn {
        margin-top: 10px;
        // margin-bottom: 10px;
      }

      .dropdown {
        text-align: center;
      }
    }
}
/************************************************
END SMALL DEVICES, TABLETS
************************************************/


/************************************************
3. START SMALL DEVICES, TABLETS. SIZE(min-width: 768px) and (max-width: 991px)
************************************************/
@media (min-width: 768px) and (max-width: 991px) {
 
}
/************************************************
END SMALL DEVICES, TABLETS
************************************************/



/************************************************
4. START SMALL DEVICES, PHONES. SIZE(max-width: 767px)
************************************************/
@media (max-width: 767px) {
  
}
/************************************************
END SMALL DEVICES, PHONES
************************************************/


/************************************************
5. START EXTRA SMALL DEVICES, PHONES. SIZE(max-width: 480px)
************************************************/
@media (max-width: 480px) {
 
}
/************************************************
END EXTRA SMALL DEVICES, PHONES
************************************************/


/************************************************
6. START EXTRA SMALL DEVICES, PHONES. SIZE(max-width: 320px)
************************************************/
@media (max-width: 320px) {
 
}
/************************************************
END EXTRA SMALL DEVICES, PHONES
************************************************/
