body.menu-clopsed {
  .sidebar {
    width: 70px;

    .list-group-item {
      padding: 10px;

      span {
        display: none;
      }

      &:hover {
        width: 200px;
        display: -webkit-inline-box;
        transition: 0.2s;
        height: 41px;

        span {
          display: block;
          transition: 0.1s;
        }
      }
    }
  }
}

.list-group-item.active,
.list-group-item:hover,
.list-group-item.dark:hover {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  border-radius: 5px;
  color: #fff !important;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.23);
}

@media (max-width: 992px) {
  main {
    padding-left: 0px !important;
  }
}
