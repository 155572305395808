.both_side_border {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #6F6F6F;

  &::before,
  &::after {
    content: '';
    border-top: 1px solid;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
    color:  #6F6F6F;
  }

  &::after {
    margin: 0 0 0 20px;
  }
}
.question_text{
  margin-bottom: 5px;
  font-weight: bold;
}

