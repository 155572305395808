.uploader-box {
  .uploader-field {
    display: none;
  }

  .uploader-actions {
    margin-top: 5px;
    
    .uploader-action-btn {
      cursor: pointer;
      width: 25px;
      
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}