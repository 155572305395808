.product-card {
    margin-bottom: 20px;
    cursor: pointer;

    .card-img {
       min-height: 200px;
      //  background-image: url('assets/image/products/lvchanelbalenc_web.png');
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
       margin-bottom: 5px;
    }

    .card-title {
      font-size: 18px;
    }
}