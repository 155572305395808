.table-header {
  padding-bottom: 25px;
  justify-content: space-between;
}

.table-dropdown {
  width: 20px;
  text-align: center;
  cursor: pointer;
}

.WQNxq {
  border-top: 1px solid #ccc;
}
