.cursor-pointer {
  cursor: pointer;
}

.border-top-1 {
  border-top: 1px solid $color-gray;
}

.text-input-center {
  text-align: center;
}

.textarea-resize-none {
  resize: none;
}

.max-width-200 {
  max-width: 200px;
}

.width-80 {
  width: 80px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.dropdown-item-group {
  .dropdown-item-icon {
    margin-right: 10px;
  }
}

//Scrollbar Start
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-clip: padding-box;
  border: 0px solid transparent;
  color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

//Scrollbar End

.row {
  margin: 0;
}

.default-badge {
  background-color: $color-violet !important;
}

.logged-user-color {
  color: rgb(89, 190, 154);
}

/******** Checkbox *******/
.bns-checkbox {
  position: absolute;
  opacity: 0;
}

.bns-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.bns-checkbox + label:before {
  content: '';
  margin-right: 0.9rem;
  margin-top: -0.15rem;
  display: inline-block;
  vertical-align: text-top;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #eae9e9;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.bns-checkbox.round + label:before {
  border-radius: 50px !important;
}

.bns-checkbox:hover + label:before,
.bns-checkbox:checked + label:before {
  border-color: #644bef;
}

.bns-checkbox:checked + label:before {
  background-color: #644bef;
}

.bns-checkbox:disabled + label {
  color: #c7c6c6;
  cursor: not-allowed;
}

.bns-checkbox:disabled + label:before {
  background: #f9f9f9;
  border: 1px solid #eae9e9;
}

.bns-checkbox:not(:disabled):hover + label,
.bns-checkbox:not(:disabled):checked + label {
  // color: #644bef;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bns-checkbox:not(:disabled):checked + label:after {
  content: '';
  position: absolute;
  left: 0.43rem;
  top: 0.70rem;
  width: 2px;
  height: 2px;
  transform: rotate(45deg);
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 2px 0 0 #ffffff, 4px 0 0 #ffffff, 4px -2px 0 #ffffff, 4px -4px 0 #ffffff, 4px -6px 0 #ffffff, 4px -8px 0 #ffffff;
}

.bns-checkbox.bns-card + label:before,
.bns-checkbox.bns-card:checked + label:before {
  position: absolute;
  right: 5px;
  top: 18px;
  z-index: 1;
}

.bns-checkbox.bns-card:not(:disabled):checked + label:after {
  right: 35px;
  z-index: 2;
  top: 26px;
  left: auto;
}

.bns-checkbox:not(:disabled):checked + label>.category-card-item {
  box-shadow: 0 1px 14px 0px #8e63fe;
  border-color: transparent;
}