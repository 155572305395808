.img-20 {
  height: 25px;
}
.img-25 {
  height: 25px;
}
.img-30 {
  height: 30px;
}
.img-40 {
  height: 40px;
}
.img-50 {
  height: 50px;
}
.img-60 {
  height: 60px;
}
.img-70 {
  height: 70px;
}
.img-80 {
  height: 80px;
}
.img-90 {
  height: 90px;
}
.img-100 {
  height: 100px;
}
.img-150 {
  height: 150px;
}
.img-200 {
  height: 200px;
}
