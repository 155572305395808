.dropdown-container {
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 5px;
  margin-left: 12px;
  margin-right: 12px;
  ul {
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    list-style: none;
    cursor: pointer;
  }
  .active {
    background-color: #7367f0 !important;
    color: #fff !important;
    border-radius: 5px;
  }
}
