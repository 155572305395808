$color-white: #fff;
$color-red: #e52222;
$color-gray-red: #e5222299;
$color-black: #212529;
$color-white-gray: #f8f8f8;

$color-gray-dark: #495057;
$color-white-dark: #ecf0f1;

$color-blue: #0e6efd;
$color-gray: #eeeeee;

$primary-color: #FFEEE7;

$Greyish-Turquoise: #607d84;
$color-gray-light: #eeeeee;

$Green-Peas: #8bc34a;
$border-radius: 30px;

$color-violet: #8E63FF;

* {
  --bs-link-color: #7367f0;
  --bs-link-hover-color: #557db8;
}

//For All
.bg-primary {
  background-color: #7367f0 !important;
}

.text-primary {
  color: #7367f0 !important;
}